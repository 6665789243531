@import '../../variables';

.desktop {
  nav {
    font-family: $font-sans-serif-bold;
    @include flexbox();
    @include align-items(center);
  }

  ul {
    position: relative;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);

    li {
      cursor: pointer;
      display: inline-block;
      font-size: 1.2rem;
      font-weight: 700;
      letter-spacing: 0.1rem;
      margin: 0 1vw;
      padding: 4rem 0;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;
      @include transition;

      .active {
        border-color: $color-link;
        color: $color-link;

        &:hover {
          background-color: $color-white;
          border-color: $color-link;
          color: $color-link;
        }
      }

      ul {
        background-color: $color-white;
        display: none;
        height: auto;
        padding: 1.5rem 0;
        position: absolute;
        left: -2rem;
        top: 9rem;

        li {
          color: $color-primary;
          display: block;
          margin: 0;
          padding: 1rem 2rem;
          padding-right: 5rem;
          position: relative;
          text-align: left;
          white-space: nowrap;

          &:hover {
            background-color: $color-soft;
            color: $color-primary;

            a {
              color: $color-primary;
            }

            ul {
              min-height: inherit;
              left: 100%;
              min-width: 55rem;
              max-width: 55rem;
              top: -1.5rem;
              @include flexbox();
              @include align-items(flex-start);
              @include flex-wrap(wrap);
              @include justify-content(flex-start);

              li {
                padding-left: 4rem;
                width: 50%;
                white-space: normal;

                &::after {
                  content: none;
                }
              }
            }
          }

          &::after {
            content:  '>';
            right: 2rem;
            position: absolute;
          }

          &:last-child::after {
            content: none;
          }
        }
      }

      &:hover {
        color: $color-link;

        ul {
          display: block;

          ul {
            display: none;
          }
        }
      }

      &.button {
        padding: 0.5rem;

        &:hover a {
          color: $color-white;
        }
      }
    }
  }
}

.mobile {
  nav {
    font-family: $font-sans-serif-bold;
  }

  ul {
    li {
      display: block;
      font-size: 1.2rem;
      font-weight: bold;
      letter-spacing: 0.1rem;
      padding: 1rem 0;
      text-align: left;
      text-transform: uppercase;

      a {
        display: block;
      }

      .button {
        border: 0;
        padding: 0;
        text-align: left;

        &:hover {
          background-color: transparent;
          color: $color-link;
        }
      }

      &.search-block {
        display: none;
      }

      ul {
        display: none;
      }
    }
  }
}
